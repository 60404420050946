<template>
  <div>
    <Section1 :keyWord="'Préstamo para jubilados: una opción para cada necesidad'" />
    <div class="texto-landing">
      <!-- Escribe aquí gaston, gracias :) -->
      <h2 class="title-h1 text-center pb-4"><strong>Descubre nuestro mejor préstamo para jubilados</strong></h2>
      <p>En Ibancar, nos enorgullecemos de brindar soluciones financieras personalizadas y diseñadas específicamente para satisfacer las necesidades únicas de cada uno de nuestros clientes.</p>
      <p>Entendemos que, como jubilado, es posible que desees obtener un préstamo que te ayude a financiar tus proyectos personales, ya sean viajes, mejoras en el hogar o cualquier otra iniciativa importante en tu vida.</p>
      <p>Por eso hemos desarrollado nuestro préstamo para jubilados, una opción ideal y adaptada especialmente para personas como tú, que se encuentran disfrutando de esta etapa de sus vidas y desean contar con un respaldo financiero que les permita cumplir con sus objetivos y sueños</p>

      <h3 class="naranja"><strong>La jubilación no significa el fin de tus sueños</strong></h3>
      <p>Entendemos que la jubilación puede traer consigo cambios en tus ingresos y en tu capacidad para acceder a créditos. Sin embargo, esto no significa que tengas que renunciar a tus sueños o proyectos personales. </p>
      <p>En Ibancar, nos esforzamos por brindarte soluciones de financiamiento asequibles y al alcance de tu mano, permitiéndote seguir cumpliendo tus objetivos. Por esta razón, hemos diseñado nuestro préstamo para jubilados con el propósito de proporcionarte la estabilidad y tranquilidad financiera que mereces.</p>

      <h3 class="naranja"><strong>Préstamo para jubilados con coche como aval: La solución que necesitas</strong></h3>
      <p>Con nuestro <strong>préstamo para jubilados</strong>, te ofrecemos la posibilidad de utilizar tu coche como aval para conseguir el financiamiento que requieres, sin la necesidad de pagar un alquiler adicional por su uso o realizar cambios en la titularidad del vehículo. Podrás seguir utilizando tu vehículo sin complicaciones durante toda la duración del contrato. </p>
      <p>Al optar por el préstamo para jubilados, no solo estarás accediendo al capital que necesitas para cubrir tus gastos o llevar a cabo tus proyectos, sino que también mantendrás la libertad y movilidad que te proporciona tu coche. Así, podrás continuar realizando tus actividades cotidianas sin que se vea afectada tu calidad de vida en ningún momento.</p>

      <h3 class="naranja"><strong>¿Cómo funciona el préstamo para jubilados con Ibancar?</strong></h3>
      <ol>
        <li>Deberás rellenar nuestro formulario en línea con tu información personal y la de tu vehículo.</li>
        <li>Luego, recibirás una propuesta personalizada sin ningún compromiso.</li>
        <li>Si estás de acuerdo con la propuesta, deberás enviar la documentación requerida.</li>
        <li>Una vez que hayas enviado los documentos, recibirás el dinero en tu cuenta bancaria en un plazo de menos de 24 horas.</li>
      </ol>
      
      <h3 class="naranja"><strong>Proceso rápido y sencillo</strong></h3>
      <p>Nuestro proceso de solicitud y aprobación es rápido y sin complicaciones. De esta manera, podrás tener acceso al dinero que necesitas en el menor tiempo posible.</p>
      
      <h3 class="naranja"><strong>Equipo de expertos a tu disposición</strong></h3>
      <p>Nuestro equipo de profesionales está disponible para responder a tus preguntas y brindarte la asesoría que necesites durante todo el proceso.</p>
        
      <h3 class="naranja"><strong>¡Empieza hoy mismo!</strong></h3>
      <p>No dejes que las preocupaciones financieras te impidan disfrutar de tu jubilación. Solicita hoy mismo tu <strong>préstamo para jubilados</strong> con Ibancar y descubre cómo podemos ayudarte a hacer realidad tus sueños y proyectos. ¡Estamos aquí para apoyarte en cada paso del camino!</p>

      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column pt-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>
    </div>
    <Section2 />
    <CreditoCocheS4 /> <!-- acordeón de preguntas -->
    <div class="relleno"></div>
    
    <div class="cards-prestamo">
      <div class="d-flex flex-row justify-content-center align-items-center flex-wrap pt-2">
        <div class="padding">
            <h2 class="title-h1 text-center">Préstamo por tu coche</h2>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
          <div class="card m-4"  >
            <a href="../prestamo-por-tu-coche" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamo por tu coche</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Entra aquí y conoce todo lo que debes saber para conseguir un préstamo por tu coche y resolver tus problemas.</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../credito-por-tu-coche" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito por tu coche</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue un crédito por tu coche. Crédito por tu coche Online</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../dinero-rapido-con-tu-coche" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Dinero rápido con tu coche</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero fácil con tu coche. Préstamo rápido y fácil por tu coche. Préstamos online por tu coche.</p>
              </div>
            </a>
          </div>
      </div>
      
      
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4">
            <a href="../credito-por-tu-coche-en-madrid" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito por tu coche en Madrid</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Crédito por tu coche en Madrid. Recibe dinero por tu coche en Madrid</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../credito-por-tu-coche-en-sevilla" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito por tu coche en &nbsp; Sevilla</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Credito por tu coche en Sevilla. Recibe dinero por tu coche en Sevilla</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../credito-por-tu-coche-en-valencia" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito por tu coche en Valencia</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Crédito por tu coche en Valencia. Recibe dinero por tu coche en Valencia.</p>
              </div>
            </a>
          </div>
      </div>

      
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../credito-aval-coche-las-palmas" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito aval coche Las &nbsp; Palmas</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero usando tu coche como aval sin cambiar de titularidad.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-con-asnef-y-nomina-rapidos" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos con Asnef y nómina rápidos</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Ya puedes conseguir préstamos con Asnef y nómina de manera muy sencilla.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-con-aval-de-coche-sin-cambiar-de-titular" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos con aval de coche sin cambiar de titular</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche sin cambiar la titularidad.</p>
              </div>
            </a>
          </div>
      </div> 

<!-- fila landing 4  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
        <div class="card m-4"  >
            <a href="../prestamos-con-aval-del-coche-de-mas-de-10-anos" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos con aval del coche de mas de 10 años</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Tenemos toda la información que necesitas leer sobre esto.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-por-tu-coche-alicante" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos por tu coche Alicante</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">¿Necesitas un préstamo por tu coche en Alicante? Pues, tenemos la solución, entra aquí.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-aval-coche-valencia" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos aval coche &nbsp; Valencia</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche sin cambiar la titularidad.</p>
              </div>
            </a>
          </div>
    
      </div>

<!-- fila landing 5  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../credito-aval-coche-con-asnef" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito aval coche con Asnef</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche estando en Asnef.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../credito-con-aval" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito con aval</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Tenemos la solución para un crédito por tu coche con aval.</p>
              </div>
            </a>
          </div>
      </div>

      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column p-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>

    </div>
    <div class="relleno"></div>
    <!-- No te pases de aquí gaston, gracias 2.0 :) -->
  </div>
</template>

<script>
// @ is an alias to /src
import Section1 from '@/components/Home/Section1.vue'
import { useMeta } from 'vue-meta'
import store from "@/store";
import Section2 from '@/components/Home/Section2.vue'
import CreditoCocheS4 from '@/components/CreditoCoche/CreditoCocheS4.vue'

// @ is an alias to /src
export default {
  name: 'PrestamoJubilados',
  components: {
    Section1,
    Section2,
    CreditoCocheS4
  },
  setup() {

    useMeta({
      title: 'Préstamo para jubilados | Ibancar',
      meta:[
        {name:'robots', content:store.state.dominio == 'ibancar.com' ? 'index' : 'noindex'},
        {name:'description', content:'Préstamo para jubilados con Ibancar'},
        {name:'keywords', content:'prestamo para jubilados'}
      ]
    })
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
.naranja {
  color: $orange;
  font-weight: bold;
}
.texto-landing {
  padding: 3em 15em;
  line-height: 2em;
}
.card {
  width: 22rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 10rem;
}
.inline {
  display: inline;
}
.caret-sign, .caret-sign-city {
  margin-left: 25px;
  vertical-align: top;
  float: right;
}
.caret-sign-city {
  position: relative;
  top: -27px;
}
a.no {
  text-decoration: none;
  color: #333;
}
a.no:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  bottom: 1px;
  height: 10rem;
}
.relleno {
  height: 50px;
}

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs,sm) {
  .texto-landing {
    padding: 1.5em 2em;
  }
  .card {
    height: 100%;
  }
  a.no:hover {
    height: 100%;
  }
  .title-h1 {
    font-size: 32px;
  }
  .naranja {
    font-size: 24px;
  }
}

@include media-breakpoint-between(md,lg) {
  .texto-landing {
    padding: 1.5em 5em;
  }
}

@include media-breakpoint-between(xl,xxl) {

}

</style>