<template>
  <div>
    <Section1 :keyWord="'Consigue un préstamo de 3.000€ hoy, con tu coche como aval.'" />
    <div class="texto-landing">
      <!-- Escribe aquí gaston, gracias :) -->
      <h2 class="title-h1 text-center pb-4"><strong>Recibe los 3.000€ que necesitas hoy, fácil y rápido. </strong></h2>
      <h3 class="naranja"><strong>Consigue 3000 euros con nuestro préstamo rápido</strong></h3>
      <p>Gracias al <strong>préstamo 3000 euros rápido</strong> podrás financiar una escapada de fin de semana o ese viaje en familia que tanto tiempo llevas planificando.</p>
      <p>Hacer frente a un pago urgente, financiar los estudios de tus hijos o un proyecto personal, etc.  El <strong>préstamo 3000 euros rápido</strong> de Ibancar es la mejor solución para añadir liquidez a tu cuenta bancaria en un tiempo récord.</p>
      <p>¿Sabías que todo el proceso se completa de manera 100% online y el dinero es transferido a tu cuenta en el mismo día?</p>
      <p>Sigue con nosotros para descubrir todas las ventajas de este préstamo rápido de Ibancar.</p>

      <h3 class="naranja"><strong>Cómo funciona el préstamo rápido de 3000 euros</strong></h3>
      <p>En el <strong>préstamo 3000 euros rápido</strong>, tu vehículo actúa como aval del préstamo. Podrás seguir conduciendo tu vehículo sin ningún tipo de restricción, e incluso aprovecharte de las ventajas exclusivas a las que tienes derecho como cliente de Ibancar.</p>

      <h3 class="naranja"><strong>Ventajas del préstamo rápido de 3000 euros de Ibancar</strong></h3>
      <p>Las ventajas del préstamo 3000 euros rápido de Ibancar comienzan desde el mismo momento que formalizas tu solicitud, ya que se te asigna un gestor personal para asesorarte durante todo el proceso.</p>
      <p>Una vez recibido el dinero en tu cuenta, tendrás acceso a beneficios exclusivos como:</p>
      <ul>
        <li>Podrás cancelar el préstamo de manera sencilla en cualquier momento.</li>
        <li>Tienes 5 días de cortesía para abonar las cuotas de cada mensualidad.</li>
        <li>Puedes elegir la fecha de pago que mejor te convenga.</li>
        <li>Puedes solicitar meses de carencia.</li>
        <li>Cuentas con un área personal dentro de la web de Ibancar para gestionar solicitudes y pagos de tu préstamo.</li>
        <li>El préstamo rápido te da derecho a una revisión técnica anual de tu vehículo gratuita.</li>
        <li>Te ofrecemos un servicio de venta en caso de que no puedas hacer frente a los pagos.</li>
      </ul>

      <h3 class="naranja"><strong>Cómo solicitar el préstamo rápido de 3000 euros</strong></h3>
      <p>Ya conoces todos los beneficios del <strong>préstamo 3000 euros rápido de Ibancar</strong>. Ahora vamos a ayudarte a solicitarlo paso a paso.</p>
      <p>Lo primero que tienes que hacer es rellenar el formulario indicando la cantidad qué necesitas y algunos datos básicos de tu vehículo.</p>
      <p>Una vez completado el formulario, visualizarás una propuesta de préstamo pre aprobada que llegará también a tu bandeja de correo electrónico y a tu teléfono móvil a través de WhatsApp.</p>
      <p>Es el momento de aportar la documentación adicional para formalizar el contrato. Nuestros técnicos se encargan de validarla en el mismo día para agilizar el proceso.</p>
      <p>Puedes enviarnos toda la documentación a través de correo electrónico en la propia web de Ibancar a través de WhatsApp.</p>
      <p>Una vez firmado el contrato, Ibancar realiza la transferencia de manera inmediata a tu cuenta para que puedas disponer de tu dinero en el mismo día.</p>
      <p>Recuerda que cuentas con un gestor personal para ayudarte durante todo el proceso. Contacta ahora mismo con Ibancar y en menos de 24 horas estarás disfrutando de toda la liquidez que necesitas en tu cuenta bancaria.</p>


      <!-- Boton Préstamo -->
      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column pt-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>
    </div>
    <Section2 />
    <CreditoCocheS4 /> <!-- acordeón de preguntas -->
    <div class="relleno"></div>
    
    <div class="cards-prestamo">
      <div class="d-flex flex-row justify-content-center align-items-center flex-wrap pt-2">
        <div class="padding">
            <h2 class="title-h1 text-center">Préstamo por tu coche</h2>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
          <div class="card m-4"  >
            <a href="../prestamo-por-tu-coche" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamo por tu coche</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Entra aquí y conoce todo lo que debes saber para conseguir un préstamo por tu coche y resolver tus problemas.</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../credito-por-tu-coche" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito por tu coche</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue un crédito por tu coche. Crédito por tu coche Online</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../dinero-rapido-con-tu-coche" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Dinero rápido con tu coche</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero fácil con tu coche. Préstamo rápido y fácil por tu coche. Préstamos online por tu coche.</p>
              </div>
            </a>
          </div>
      </div>
      
      
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4">
            <a href="../credito-por-tu-coche-en-madrid" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito por tu coche en Madrid</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Crédito por tu coche en Madrid. Recibe dinero por tu coche en Madrid</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../credito-por-tu-coche-en-sevilla" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito por tu coche en &nbsp; Sevilla</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Credito por tu coche en Sevilla. Recibe dinero por tu coche en Sevilla</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../credito-por-tu-coche-en-valencia" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito por tu coche en Valencia</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Crédito por tu coche en Valencia. Recibe dinero por tu coche en Valencia.</p>
              </div>
            </a>
          </div>
      </div>

      
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../credito-aval-coche-las-palmas" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito aval coche Las &nbsp; Palmas</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero usando tu coche como aval sin cambiar de titularidad.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-con-asnef-y-nomina-rapidos" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos con Asnef y nómina rápidos</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Ya puedes conseguir préstamos con Asnef y nómina de manera muy sencilla.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-con-aval-de-coche-sin-cambiar-de-titular" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos con aval de coche sin cambiar de titular</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche sin cambiar la titularidad.</p>
              </div>
            </a>
          </div>
      </div> 

<!-- fila landing 4  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
        <div class="card m-4"  >
            <a href="../prestamos-con-aval-del-coche-de-mas-de-10-anos" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos con aval del coche de mas de 10 años</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Tenemos toda la información que necesitas leer sobre esto.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-por-tu-coche-alicante" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos por tu coche Alicante</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">¿Necesitas un préstamo por tu coche en Alicante? Pues, tenemos la solución, entra aquí.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-aval-coche-valencia" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos aval coche &nbsp; Valencia</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche sin cambiar la titularidad.</p>
              </div>
            </a>
          </div>
    
      </div>

<!-- fila landing 5  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../credito-aval-coche-con-asnef" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito aval coche con Asnef</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche estando en Asnef.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../credito-coche" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito con aval</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Tenemos la solución para un crédito por tu coche con aval.</p>
              </div>
            </a>
          </div>
      </div>

      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column p-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>

    </div>
    <div class="relleno"></div>
    <!-- No te pases de aquí gaston, gracias 2.0 :) -->
  </div>
</template>

<script>
// @ is an alias to /src
import Section1 from '@/components/Home/Section1.vue'
import { useMeta } from 'vue-meta'
import store from "@/store";
import Section2 from '@/components/Home/Section2.vue'
import CreditoCocheS4 from '@/components/CreditoCoche/CreditoCocheS4.vue'

// @ is an alias to /src
export default {
  name: 'PrestamoRapido10000Euros',
  components: {
    Section1,
    Section2,
    CreditoCocheS4
  },
  setup() {

    useMeta({
      title: 'Préstamo rápido de 3000€ ▷ Con tu coche como aval, 100% online y seguro | Ibancar',
      meta:[
        {name:'robots', content:store.state.dominio == 'ibancar.com' ? 'index' : 'noindex'},
        {name:'description', content:'En Ibancar te podemos conceder un préstamo rápido de 3000€ y todo el proceso es online, obtén el dinero en menos de 24 horas'},
        {name:'keywords', content:'prestamo rapido 3000 euros'}
      ]
    })
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
.naranja {
  color: $orange;
  font-weight: bold;
}
.texto-landing {
  padding: 3em 15em;
  line-height: 2em;
}
.card {
  width: 22rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 10rem;
}
.inline {
  display: inline;
}
.caret-sign, .caret-sign-city {
  margin-left: 25px;
  vertical-align: top;
  float: right;
}
.caret-sign-city {
  position: relative;
  top: -27px;
}
a.no {
  text-decoration: none;
  color: #333;
}
a.no:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  bottom: 1px;
  height: 10rem;
}
.relleno {
  height: 50px;
}

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs,sm) {
  .texto-landing {
    padding: 1.5em 2em;
  }
  .card {
    height: 100%;
  }
  a.no:hover {
    height: 100%;
  }
  .title-h1 {
    font-size: 32px;
  }
  .naranja {
    font-size: 24px;
  }
}

@include media-breakpoint-between(md,lg) {
  .texto-landing {
    padding: 1.5em 5em;
  }
}

@include media-breakpoint-between(xl,xxl) {

}
</style>