<template>
    <div>
      <Section1 :keyWord="'Préstamo para placas solares con Ibancar'" />
      <div class="texto-landing">
        <!-- Escribe aquí gaston, gracias :) -->
        <h2 class="title-h1 text-center pb-4"><strong>Préstamo para placas solares</strong></h2>
        <p>Cada vez son más las familias que optan por la instalación en sus viviendas de placas solares para poder así abaratar los gastos a los que tienen que hacer frente. Pero además de reducir las facturas a final de mes, con soluciones como las placas solares contribuiremos a la sostenibilidad del medio ambiente y a su conservación para generaciones futuras.</p>
        <p>Estos son algunos de los motivos por los que cada vez más usuarios deciden realizar la instalación, sin embargo aunque a la larga resulta muy beneficiosa, de primera mano necesita una inversión económica importante, y aquí es donde Ibancar entra en juego. Nuestro <strong>préstamo para placas solares</strong> es una opción perfecta para aquellos que no disponen de esa cantidad de dinero para su instalación.</p>
  
        <h3 class="naranja"><strong>¿Cómo funciona Ibancar con su préstamo para placas solares?</strong></h3>
        <p>Con el <strong>préstamo para placas solares</strong> de Ibancar podrás acceder a la instalación de los paneles para conseguir al fin reducir tus facturas mensuales. Con todas las ventajas de un minicrédito en cuanto a rapidez resulta mucho más económico, ya que podrás obtener el crédito que necesitas solo avalando tu coche.</p>
        <p>Gracias a Ibancar obtendrás el dinero que necesitas para tus paneles en menos de 24 horas, sin más aval que el de tu propio coche. Además podrás seguir disfrutando del vehículo como venías haciendo hasta ahora, ya que el aval no implica en ningún momento un cambio de titularidad. Con el préstamo para placas solares solo existe una limitación, y es que no podrás vender tu coche mientras dure el crédito.</p>
  
        <h3 class="naranja"><strong>Conoce las ventajas que te ofrece Ibancar</strong></h3>
        <p>Si ha llegado la hora de pasarte al ahorro y a la sostenibilidad, y sin embargo necesitas un <strong>préstamo para placas solares</strong>, <a href="https://ibancar.com">Ibancar pone a tu servicio una serie de ventajas que te harán conseguir ese dinero inicial que necesitas, con pocos trámites y de una forma cómoda.</a> Entre las ventajas que te ofrecemos en Ibancar encontrarás:</p>
        <ul>
            <li>No se hace cambio de titularidad del coche, el dueño y titular seguirás siendo tú.</li>
            <li>No habrá alquiler ni retirada del vehículo avalado.</li>
            <li>Obtendrás el dinero que necesitas en tan solo 24 horas.</li>
            <li>El proceso de solicitar el crédito es sencillo y online. Podrás solicitar el crédito desde tu casa o desde el sitio donde te encuentres sin necesidad de desplazamientos.</li>
            <li>Amortización del préstamo desde la primera cuota del mismo.</li>
            <li>Pack de servicios incluidos.</li>
            <li>No será necesario que entregues llaves ni documentación original del vehículo.</li>
        </ul>
  
        <h3 class="naranja"><strong>¿Qué documentación debo presentar para solicitar el préstamo para placas solares?</strong></h3>
        <p>La documentación a aportar para solicitar el <strong>préstamo para placas solares</strong> se divide en la documentación personal y la documentación referida al vehículo que actuará como aval.</p>
        <ul>
            <li>Documentación personal. En cuanto al personal necesitarás presentar el DNI por ambas caras, la última nómina, pensión o autónomo y una foto selfie con el DNI.</li>
            <li>Documentación del vehículo. La documentación referida al coche será la del permiso de circulación y ficha técnica, una foto del cuentakilómetros con el DNI y cuatro fotos del coche y un video.</li>
        </ul>  
        <!-- Boton Préstamo -->
        <div class="d-flex flex-row justify-content-center align-items-center">
            <div class="flex-column pt-5">
                <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
            </div>
        </div>
      </div>
      <Section2 />
      <CreditoCocheS4 /> <!-- acordeón de preguntas -->
      <div class="relleno"></div>
      
      <div class="cards-prestamo">
        <div class="d-flex flex-row justify-content-center align-items-center flex-wrap pt-2">
          <div class="padding">
              <h2 class="title-h1 text-center">Préstamo por tu coche</h2>
          </div>
        </div>
  
        <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
            <div class="card m-4"  >
              <a href="../prestamo-por-tu-coche" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Préstamo por tu coche</h5>
                    <span class="caret-sign">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Entra aquí y conoce todo lo que debes saber para conseguir un préstamo por tu coche y resolver tus problemas.</p>
                </div>
              </a>
            </div>
            <div class="card m-4">
              <a href="../credito-por-tu-coche" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Crédito por tu coche</h5>
                    <span class="caret-sign">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Consigue un crédito por tu coche. Crédito por tu coche Online</p>
                </div>
              </a>
            </div>
            <div class="card m-4">
              <a href="../dinero-rapido-con-tu-coche" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Dinero rápido con tu coche</h5>
                    <span class="caret-sign">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Consigue dinero fácil con tu coche. Préstamo rápido y fácil por tu coche. Préstamos online por tu coche.</p>
                </div>
              </a>
            </div>
        </div>
        
        
        <div class="d-flex flex-row justify-content-center flex-wrap">
            <div class="card m-4">
              <a href="../credito-por-tu-coche-en-madrid" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Crédito por tu coche en Madrid</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Crédito por tu coche en Madrid. Recibe dinero por tu coche en Madrid</p>
                </div>
              </a>
            </div>
            <div class="card m-4">
              <a href="../credito-por-tu-coche-en-sevilla" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Crédito por tu coche en &nbsp; Sevilla</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Credito por tu coche en Sevilla. Recibe dinero por tu coche en Sevilla</p>
                </div>
              </a>
            </div>
            <div class="card m-4">
              <a href="../credito-por-tu-coche-en-valencia" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Crédito por tu coche en Valencia</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Crédito por tu coche en Valencia. Recibe dinero por tu coche en Valencia.</p>
                </div>
              </a>
            </div>
        </div>
  
        
        <div class="d-flex flex-row justify-content-center flex-wrap">
            <div class="card m-4"  >
              <a href="../credito-aval-coche-las-palmas" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Crédito aval coche Las &nbsp; Palmas</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Consigue dinero usando tu coche como aval sin cambiar de titularidad.</p>
                </div>
              </a>
            </div>
            <div class="card m-4"  >
              <a href="../prestamos-con-asnef-y-nomina-rapidos" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Préstamos con Asnef y nómina rápidos</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Ya puedes conseguir préstamos con Asnef y nómina de manera muy sencilla.</p>
                </div>
              </a>
            </div>
            <div class="card m-4"  >
              <a href="../prestamos-con-aval-de-coche-sin-cambiar-de-titular" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Préstamos con aval de coche sin cambiar de titular</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Consigue dinero con tu coche sin cambiar la titularidad.</p>
                </div>
              </a>
            </div>
        </div> 
  
  <!-- fila landing 4  -->
        <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
              <a href="../prestamos-con-aval-del-coche-de-mas-de-10-anos" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Préstamos con aval del coche de mas de 10 años</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Tenemos toda la información que necesitas leer sobre esto.</p>
                </div>
              </a>
            </div>
            <div class="card m-4"  >
              <a href="../prestamos-por-tu-coche-alicante" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Préstamos por tu coche Alicante</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">¿Necesitas un préstamo por tu coche en Alicante? Pues, tenemos la solución, entra aquí.</p>
                </div>
              </a>
            </div>
            <div class="card m-4"  >
              <a href="../prestamos-aval-coche-valencia" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Préstamos aval coche &nbsp; Valencia</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Consigue dinero con tu coche sin cambiar la titularidad.</p>
                </div>
              </a>
            </div>
      
        </div>
  
  <!-- fila landing 5  -->
        <div class="d-flex flex-row justify-content-center flex-wrap">
            <div class="card m-4"  >
              <a href="../credito-aval-coche-con-asnef" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Crédito aval coche con Asnef</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Consigue dinero con tu coche estando en Asnef.</p>
                </div>
              </a>
            </div>
            <div class="card m-4"  >
              <a href="../credito-coche" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Crédito con aval</h5>
                    <span class="caret-sign">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Tenemos la solución para un crédito por tu coche con aval.</p>
                </div>
              </a>
            </div>
        </div>
  
        <div class="d-flex flex-row justify-content-center align-items-center">
            <div class="flex-column p-5">
                <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
            </div>
        </div>
  
      </div>
      <div class="relleno"></div>
      <!-- No te pases de aquí gaston, gracias 2.0 :) -->
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import Section1 from '@/components/Home/Section1.vue'
  import { useMeta } from 'vue-meta'
  import store from "@/store";
  import Section2 from '@/components/Home/Section2.vue'
  import CreditoCocheS4 from '@/components/CreditoCoche/CreditoCocheS4.vue'
  
  // @ is an alias to /src
  export default {
    name: 'PrestamoDocentes',
    components: {
      Section1,
      Section2,
      CreditoCocheS4
    },
    setup() {
  
      useMeta({
        title: 'Préstamo para placas solares',
        meta:[
          {name:'robots', content:store.state.dominio == 'ibancar.com' ? 'index' : 'noindex'},
          {name:'description', content:'Préstamo para placas solares con Ibancar'},
          {name:'keywords', content:'prestamo para placas solares'}
        ]
      })
      }
  }
  </script>
  
  <style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
  .naranja {
    color: $orange;
    font-weight: bold;
  }
  .texto-landing {
    padding: 3em 15em;
    line-height: 2em;
  }
  .card {
    width: 22rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    height: 10rem;
  }
  .inline {
    display: inline;
  }
  .caret-sign, .caret-sign-city {
    margin-left: 25px;
    vertical-align: top;
    float: right;
  }
  .caret-sign-city {
    position: relative;
    top: -27px;
  }
  a.no {
    text-decoration: none;
    color: #333;
  }
  a.no:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    bottom: 1px;
    height: 10rem;
  }
  .relleno {
    height: 50px;
  }
  
  @import 'bootstrap/scss/_functions.scss';
  @import 'bootstrap/scss/_variables.scss';
  @import 'bootstrap/scss/_mixins.scss';
  
  @include media-breakpoint-between(xs,sm) {
    .texto-landing {
      padding: 1.5em 2em;
    }
    .card {
      height: 100%;
    }
    a.no:hover {
      height: 100%;
    }
    .title-h1 {
      font-size: 32px;
    }
    .naranja {
      font-size: 24px;
    }
  }
  
  @include media-breakpoint-between(md,lg) {
    .texto-landing {
      padding: 1.5em 5em;
    }
  }
  
  @include media-breakpoint-between(xl,xxl) {
  
  }
  </style>